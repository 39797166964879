import { ApplicationRef, Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Chapter } from '../interfaces/chapter';
import { Course } from '../interfaces/course';
import { Lesson } from '../interfaces/lesson';
import { Paragraph } from '../interfaces/paragraph';
import { CourseUtilitiesService } from '../utilities/course-utilities.service';

import { ApiService } from './api.service';
import { ToastService } from './toast.service';
import { DOCUMENT } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  private summaries$: Array<BehaviorSubject<Course | null>> = [];
  private collections$: Array<BehaviorSubject<Course | null>> = [];

  private pendingSummaries: Array<boolean> = [];
  private pendingCollections: Array<boolean> = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private api: ApiService,
    private utils: CourseUtilitiesService,
    private toastr: ToastService,
    private router: Router
  ) {}
  addTag(tag: { rel: string; href: string }) {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', tag.rel);
    link.setAttribute('href', tag.href);
    this.document.head.appendChild(link);
  }

  async getPublic(
    limit: number | null = 50,
    page: number | null = 1,
    order: string | null,
    search: string | null,
    lang: string | null = ''
  ) {
    if (!order) {
      order = 'RELEVANCE';
    }

    try {
      let query = `?type=course&limit=${limit}&page=${page}&language=${lang}&search=${search}&order=${order}&owner=&section=all&public=true`;
      const courses = await this.api.get('/collection/get' + query);
      return courses;
    } catch (error) {
      return error;
    }
  }
  async getDevelopment(type: string, lang: string) {
    try {
      let query = `?limit=100&subject=${type}&language=${lang}&type=course`;
      const courses = await this.api.get('/collection/get' + query);
      return courses;
    } catch (error) {
      return error;
    }
  }

  async getEnrolled(
    limit: number | null = 50,
    page: number | null = 1,
    order: string | null,
    search: string | null
  ) {
    order = 'DESC';

    try {
      let query = `?type=course&limit=${limit}&page=${page}&search=${search}&order=${order}&owner=&section=joined&joined=true`;
      const courses = await this.api.get('/collection/get/enrolled' + query);
      return courses;
    } catch (error) {
      return error;
    }
  }

  loadCourseSummary(id: number): void {
    if (!this.pendingSummaries[id]) {
      if (!this.summaries$[id]) {
        this.summaries$[id] = new BehaviorSubject<Course | null>(null);
      }
      this.pendingSummaries[id] = true;
      this.api
        .get('/courses/' + id + '/summary')
        .then((response) => {
          // sort chapters
          this.utils.sort(response.data);

          this.summaries$[id].next(response.data);
        })
        .catch((err) => {
          if (err.status == 404) {
            this.toastr.showError(err.error.message.friendly);
            this.router.navigate(['/courses']);
          }
          this.summaries$[id].next(null);
        })
        .finally(() => {
          this.pendingSummaries[id] = false;
        });
    }
  }

  loadCollection(id: number): void {
    if (!this.pendingCollections[id]) {
      if (!this.collections$[id]) {
        this.collections$[id] = new BehaviorSubject<Course | null>(null);
      }
      this.pendingCollections[id] = true;
      this.api.get('/collection/' + id).then((response) => {
        if (response.result == 'error') {
          this.toastr.showError(response.message.friendly);
        }
        this.utils.sort(response.data.collection);
        this.utils.calculateProgress(response.data.collection);

        this.collections$[id].next(response.data.collection);
        // this.app.tick();
        this.pendingCollections[id] = false;
      });
    }
  }

  getObservableCollection(id: number): Observable<Course | null> {
    if (!this.collections$[id]) {
      this.loadCollection(id);
    }
    return this.collections$[id]?.asObservable();
  }

  getObservableSummary(id: number, forced = false): Observable<Course | null> {
    if (!this.summaries$[id] || forced) {
      this.loadCourseSummary(id);
    }

    return this.summaries$[id]?.asObservable();
  }

  async getLastActiveCourse(userId: number) {
    return this.api.get('/user/' + userId + '/activecourses');
  }
  async getPreviewLesson() {
    return await this.api.get(
      '/dashboard/newsections?limit=99&published=true&type=lessons'
    );
  }

  async completeLesson(course: Course, lesson: Lesson) {
    let that = this;
    return this.api
      .postJson(
        '/chapter/' + lesson.chapter_id + '/lesson/' + lesson.id + '/user',
        { completed: true }
      )
      .then();
  }

  async uncompleteLesson(course: Course, lesson: Lesson) {
    let that = this;
    return this.api
      .postJson(
        '/chapter/' + lesson.chapter_id + '/lesson/' + lesson.id + '/user',
        { completed: false }
      )
      .then();
  }

  saveVideoOnChapter(chapter: any, video: any) {
    let data = {
      type: 'video',
      video_post_id: video.entity_id,
      description: video.description,
      name: video.name,
    };
    return this.api
      .postJson('/chapter/' + chapter + '/lesson', data)
      .then((res: any) => {
        return res.data;
      });
  }
  saveLessonOnChapter(lesson: Lesson | any, params: any) {
    return this.api
      .put('/chapter/' + lesson.chapter_id + '/lesson/' + lesson.id, params)
      .then((res: any) => {
        return res.data;
      });
  }

  createLessonOnChapter(chapter: Chapter | any, lesson: any) {
    delete lesson.chapter_id;
    return this.api
      .postJson('/chapter/' + chapter.id + '/lesson', lesson)
      .then((res: any) => {
        return res.data;
      });
  }

  deleteLesson(lesson: Lesson) {
    return this.api
      .delete('/chapter/' + lesson.chapter_id + '/lesson/' + lesson.id)
      .then((res: any) => {
        return res.data;
      });
  }

  orderLessons(chapter_id: number, params: any) {
    return this.api
      .patch('/chapter/' + chapter_id + '/lesson/order', params)
      .then((res: any) => {
        return res.data;
      });
  }
  patchMessage(courseId: number, lessonId: any) {
    return this.api
      .patch('/courses/' + courseId + '/lesson/' + lessonId + '/message')
      .then((res: any) => {
        return res.data;
      });
  }
  // postMessage(courseId: number, lessonId: any) {
  //   return this.api
  //     .postJson('/courses/' + courseId + '/lesson/' + lessonId + '/message')
  //     .then((res: any) => {
  //       return res.data;
  //     });
  // }

  saveLessonBlock(chapterId: any, lessonId: any, data: any) {
    let dataTosend = data;
    if (data.type == 'text') {
      dataTosend = {
        description: data.description,
        position: data.position,
        optional_reading: data.optional_reading,
        type: data.type,
      };
    }
    if (data.type == 'file') {
      dataTosend = {
        file_id: data.file_id,
        position: data.position,
        type: data.type,
      };
    }

    if (data.type == 'circle') {
      dataTosend = {
        position: data.position,
        type: data.type,
        json_data: data.json_data,
      };
    }
    if (data.type == 'test') {
      dataTosend = {
        position: data.position,
        type: data.type,
        json_data: JSON.stringify({
          test_type: data.test_type,
          question: data.question,
          explanation: data.explanation,
          description: data.description,
          expert_answer: data.expert_answer,
          answers: data.answers,
        }),
      };
    }
    if (data.type == 'video') {
      delete dataTosend['touched'];
    }
    return this.api
      .postJson(
        '/chapter/' + chapterId + '/lesson/' + lessonId + '/block',
        dataTosend
      )
      .then((res: any) => {
        return res.data;
      });
  }
  createParagraphOnChapter(chapter: Chapter, paragraph: any) {
    delete paragraph.chapter_id;
    delete paragraph.lessons;
    return this.api
      .postJson('/chapter/' + chapter.id + '/paragraph', paragraph)
      .then((res: any) => {
        return res.data;
      });
  }

  saveParagraphOnChapter(paragraph: Paragraph, params: any) {
    return this.api
      .put(
        '/chapter/' + paragraph.chapter_id + '/paragraph/' + paragraph.id,
        params
      )
      .then((res: any) => {
        return res.data;
      });
  }

  deleteParagraphOnChapter(paragraph: Paragraph) {
    return this.api
      .delete('/chapter/' + paragraph.chapter_id + '/paragraph/' + paragraph.id)
      .then((res: any) => {
        return res.data;
      });
  }

  updatePraragrapPreview(paragraph: Paragraph, status: boolean) {
    return this.api
      .patch(
        '/chapter/' +
          paragraph.chapter_id +
          '/paragraph/' +
          paragraph.id +
          '/preview',
        {
          free_preview: status,
        }
      )
      .then((res) => {
        return res.data;
      });
  }

  orderParagraphs(chapter_id: number, params: any) {
    return this.api
      .patch('/chapter/' + chapter_id + '/paragraph/order', params)
      .then((res: any) => {
        return res.data;
      });
  }

  createChapter(course: Course, chapter: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.api
        .postJson('/chapter', chapter)
        .then((res) => {
          this.api
            .postJson('/collection/' + course.id + '/entity', {
              entities: [
                {
                  entity_id: res.data.id,
                  type: 'chapter',
                },
              ],
            })
            .then((entres) => {
              resolve({
                chapter: res.data,
                collection_entity: entres.data.collection.entities.find(
                  (item: any) => item.entity_id == res.data.id
                ),
              });
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  saveChapter(chapter: any) {
    const chapter_id = chapter.id;
    delete chapter.id;
    return this.api.put('/chapter/' + chapter_id, chapter).then((res) => {
      return res.data;
    });
  }

  updateChapterPreview(chapter: Chapter, status: boolean) {
    return this.api
      .patch('/chapter/' + chapter.id + '/preview', {
        free_preview: status,
      })
      .then((res) => {
        return res.data;
      });
  }

  deleteChapter(chapter: Chapter) {
    return this.api.delete('/chapter/' + chapter.id).then((res: any) => {
      return res.data;
    });
  }

  orderChapters(course_id: number, params: any) {
    return this.api
      .put('/collection/' + course_id + '/entity', { entities: params })
      .then((res: any) => {
        return res.data;
      });
  }

  updateLessonPreview(lesson: Lesson, status: boolean) {
    return this.api
      .patch(
        '/chapter/' + lesson.chapter_id + '/lesson/' + lesson.id + '/preview',
        {
          free_preview: status,
        }
      )
      .then((res) => {
        return res.data;
      });
  }

  async resetProgress(course: Course) {
    return this.api
      .post('/courses/' + course.id + '/reset-progress', {})
      .then();
  }

  async newCourse(params: any): Promise<any> {
    return this.api.postJson('/collection', params);
  }

  async patchEnrollCourse(id: string) {
    try {
      const patch_enroll = await this.api.patch(
        '/collection/' + id + '/users/enroll'
      );

      return patch_enroll;
    } catch (error) {
      return error;
    }
  }

  async patchCourseOrganization(id: number, dataOrganization: any) {
    try {
      const resp = await this.api.patch('/collection/' + id + '/share', {
        id: id,
        organizations: dataOrganization.organizations,
      });
      return resp;
    } catch (error) {
      return error;
    }
  }

  async patchCourseNotOrganization(id: number, dataNotOrganization: any) {
    try {
      const resp = await this.api.patch('/collection/' + id + '/unshare', {
        id: id,
        organizations: dataNotOrganization.notOrganizations,
      });
      return resp;
    } catch (error) {
      return error;
    }
  }

  async patchEditCourse(id: number, dataEdit: {}) {
    try {
      const patchCourse = await this.api.put(`/collection/${id}`, dataEdit);
      return patchCourse;
    } catch (error) {
      return error;
    }
  }

  async patchEditCourseImg(id: number, cover_image: any) {
    try {
      const patchCourse = await this.api.put(`/collection/${id}`, {
        cover_image,
      });
      return patchCourse;
    } catch (error) {
      return error;
    }
  }

  async uploadImgCourse(courseId: number, file: File): Promise<any> {
    let formData = new FormData();
    formData.append('file', file);
    return this.api.put(`/collection/${courseId}`, formData);
  }

  async getCourseSummary(userId: number = 245) {
    try {
      return await this.api.get(`/courses/${userId}/summary`);
    } catch (error) {
      console.log(error);
    }
  }

  async patchUnenrollCourse(id: string) {
    try {
      const patch_unenroll = await this.api.patch(
        '/collection/' + id + '/users/unenroll'
      );
      return patch_unenroll;
    } catch (error) {
      return error;
    }
  }
  // 	https://api.move-e-learning.com/collection/168

  public async deleteCourse(courseId: number) {
    try {
      let url = `/collection/${courseId}`;
      const deleteCourse = await this.api.delete(url);
      return deleteCourse;
    } catch (error) {
      return error;
    }
  }

  async getUser() {
    try {
      return await this.api.get(`/user`);
    } catch (error) {
      console.log(error);
    }
  }

  ////////////
  // Reviews
  ////////////
  public async createReview(dataReview: any, courseId: number) {
    return this.api.postJson(`/collection/${courseId}/reviews`, dataReview);
  }

  public async getReviews(id: number) {
    return this.api.get(`/collection/${id}/reviews`);
  }

  public async deleteReview(courseId: number, reviewId: number) {
    try {
      let url = `/collection/${courseId}/reviews/${reviewId}`;
      const deleteReview = await this.api.delete(url);
      return deleteReview;
    } catch (error) {
      return error;
    }
  }

  async editReview(courseId: number, reviewId: number, dataReview: any) {
    try {
      const review = await this.api.put(
        `/collection/${courseId}/reviews/${reviewId}`,
        dataReview
      );
      return review;
    } catch (error) {
      return error;
    }
  }
  ///////////////////
  ///Add textLesson
  //////////////////
  async entity(courseId: string, entities: any): Promise<any> {
    return await this.api.postJson(`/collection/${courseId}/entity`, entities);
  }
  async getFlavors(videoId: number) {
    return await this.api.get('/video/' + videoId + '/flavors');
  }

  async getInfoToEdit(chapterId: number, lessonId: number) {
    return await this.api.get('/chapter/' + chapterId + '/lesson/' + lessonId);
  }

  orderBlocks(chapter_id: string, lessonId: number, params: any) {
    return this.api
      .patch(
        '/chapter/' + chapter_id + '/lesson/' + lessonId + '/block/order',
        params
      )
      .then((res: any) => {
        return res.data;
      });
  }
  async uploadFile(file: File): Promise<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.api.postFile('/file', formData).then((res) => {
      return res.data;
    });
  }

  public saveFilesPromise(chapterId: Chapter | any, lessonId: any, data: any) {
    return this.api
      .postJson('/chapter/' + chapterId + '/lesson/' + lessonId + '/file', data)
      .then((res: any) => {
        return res.data;
      });
  }

  public async deleteFile(
    chapterId: Chapter | any,
    lessonId: any,
    fileId: any
  ) {
    return await this.api
      .delete(
        '/chapter/' + chapterId + '/lesson/' + lessonId + '/file/' + fileId
      )
      .then((res: any) => {
        return res.data;
      });
  }
  public async deleteFileOnBlock(fileId: any) {
    return await this.api.delete('/file/' + fileId).then((res: any) => {
      return res.data;
    });
  }

  ////////////////////
  /// Edit text lesson
  ////////////////////

  public editLessonOnChapter(chapterId: number, lessonId: number, lesson: any) {
    delete lesson.chapter_id;
    return this.api
      .put('/chapter/' + chapterId + '/lesson/' + lessonId, lesson)
      .then((res: any) => {
        return res.data;
      });
  }

  public editLessonBlock(
    chapterId: number,
    lessonId: number,
    blockId: number,
    data: any
  ) {
    let dataTosend = data;
    if (data.type == 'text') {
      dataTosend = {
        description: data.description,
        position: data.position,
        optional_reading: data.optional_reading,
        type: data.type,
      };
    }
    if (data.type == 'file') {
      dataTosend = {
        file_id: data.file_id,
        position: data.position,
        type: data.type,
      };
    }
    if (data.type == 'circle') {
      dataTosend = {
        position: data.position,
        type: data.type,
        json_data: data.json_data,
      };
    }
    if (data.type == 'video') {
      delete dataTosend['touched'];
      dataTosend = {
        position: data.position,
        show_description: data.show_description,
        type: data.type,
        video_id: data.video_id,
      };
    }
    if (data.type == 'microclip') {
      dataTosend = {
        microclip_id: data.microclip_id,
        position: data.position,
        show_description: data.show_description,
        type: data.type,
      };
    }
    if (data.type == 'test') {
      dataTosend = {
        position: data.position,
        type: data.type,
        json_data: JSON.stringify({
          test_type: data.test_type,
          question: data.question,
          explanation: data.explanation,
          description: data.description,
          expert_answer: data.expert_answer,
          answers: data.answers,
        }),
      };
    }
    return this.api
      .put(
        '/chapter/' + chapterId + '/lesson/' + lessonId + '/block/' + blockId,
        dataTosend
      )
      .then((res: any) => {
        return res.data;
      });
  }

  public async deleteBlock(
    chapterId: number,
    lessonId: number,
    blockId: number
  ) {
    return await this.api
      .delete(
        '/chapter/' + chapterId + '/lesson/' + lessonId + '/block/' + blockId
      )
      .then((res: any) => {
        return res.data;
      });
  }

  publishCourse(course_id: number, status: boolean) {
    return this.api
      .patch('/collection/' + course_id + '/publish', {
        id: course_id,
        public: status,
      })
      .then((res: any) => {
        return res;
      });
  }

  async getMembers(courseId: number) {
    try {
      const members = await this.api.get(
        `/collection/${courseId}/users/accepted`
      );
      return members;
    } catch (error) {
      return error;
    }
  }
  async getMembersInvited(courseId: number) {
    try {
      const members = await this.api.get(
        `/collection/${courseId}/users/invited`
      );
      return members;
    } catch (error) {
      return error;
    }
  }
  async downloadPdf(courseId: number = 0) {
    try {
      const members = await this.api.get('/courses/' + courseId + '/pdf');
      return members;
    } catch (error) {
      return error;
    }
  }

  async sendInvitations(
    courseId: number,
    usersInvited: any,
    days: number,
    languange: string = ''
  ): Promise<any> {
    return this.api.postJson(`/collection/${courseId}/invite`, {
      message: usersInvited.message,
      users: usersInvited,
      subscription_days: days,
      language: languange,
    });
  }
  public async deleteInvitation(courseId: number, memberId: number) {
    try {
      let url = `/collection/${courseId}/enrolled/${memberId}`;
      const memberDeleted = await this.api.delete(url);
      return memberDeleted;
    } catch (error) {
      return error;
    }
  }
  public async deleteMemberEnrolled(courseId: number, memberId: number) {
    try {
      let url = `/collection/${courseId}/enrolled/${memberId}`;
      const memberDeleted = await this.api.delete(url);
      return memberDeleted;
    } catch (error) {
      return error;
    }
  }
}
