<header>
  <div class="header-develop">
    <div class="container">
      <div class="row pt-5 align-items-center flex-column text-center">
        <h1 class="title col-12 col-md-7 my-3 h2 text-white fw-bolder">
          {{ "landing:title" | translate }}
        </h1>
        <p class="description col-12 col-md-9 mb-4">
          {{ "landing:subtitle" | translate }}
        </p>

        <img
          class="macbook"
          src="/assets/img/dh-landing/macbook.png"
          alt=""
          srcset=""
        />
      </div>

      <div class="row justify-content-center pb-5"></div>
    </div>
  </div>
</header>
<div class="container">
  <div class="row pt-5 align-items-center flex-column text-center">
    <h2 class="title-available col-12 col-md-7 my-3">
      {{ "landing:available" | translate }}
    </h2>
    <p class="description-available col-12 col-md-9 mb-4">
      {{ "available:description" | translate }}
    </p>
  </div>
  <div class="row justify-content-center gap-5">
    <div class="col-12 col-md-6 card-1">
      <h2 class="title-competencies">
        {{ "competencies:title" | translate }}
      </h2>
      <p class="description-competencies">
        {{ "competencies:description" | translate }}
      </p>
      <button (click)="goToLevel3()" class="btn btn-competencies">
        <span style="color: #fff">{{ "learn:more" | translate }}</span>
        <img class="right" src="/assets/img/dh-landing/right.png" alt="" />
      </button>
      <img
        class="flag-free"
        [src]="
          currentLang == 'en'
            ? '/assets/img/dh-landing/flag-free.png'
            : '/assets/img/dh-landing/flag-free-2.png'
        "
        alt=""
      />
    </div>
    <div class="col-12 col-md-6 card-2">
      <h2 class="title-competencies">
        {{ "dynamic:title" | translate }}
      </h2>
      <p class="description-competencies">
        {{ "dynamic:description" | translate }}
      </p>
      <button (click)="goToLevel4()" class="btn btn-dynamic">
        <span style="color: #fff">{{ "learn:more" | translate }}</span>
        <img class="right" src="/assets/img/dh-landing/right.png" alt="" />
      </button>
    </div>
  </div>
  <div class="row pt-5 align-items-center flex-column text-center">
    <h2 class="title-available col-12 col-md-7 my-3">
      {{ "landing:title" | translate }}
    </h2>
    <p class="description-healix col-12 col-md-9 mb-4">
      {{ "healix:description" | translate }}
    </p>
  </div>
  <div class="container-levels">
    <div class="row justify-content-center">
      <div
        *ngFor="let item of infoCards"
        class="card-level col-md-10 col-11 mb-4 row"
        (click)="goTo(item?.route)"
      >
        <div class="col-auto">
          <img class="img-level" [src]="item?.img" alt="" />
        </div>
        <div class="col">
          <p class="level-title" [ngStyle]="{ color: item?.color }">
            {{ item?.level | translate }}
          </p>
          <p class="level-subtitle">{{ item?.subtitle | translate }}</p>
          <!-- <p *ngIf="item?.description" class="level-description">
            {{ item.description | translate }}
          </p> -->
          <button
            *ngIf="item?.button"
            [ngStyle]="{ background: item?.color }"
            class="btn btn-level"
          >
            <span style="color: #fff">{{ "learn:more" | translate }}</span>
          </button>
        </div>
      </div>

      <div class="row align-items-center flex-column text-center">
        <a
          class="btn mt-5 btn-primary col-auto"
          target="__blank"
          href="https://doublehealix.com/het-double-healix-model/"
          style="color: #fff"
        >
          {{ "button:learn:model" | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="footer-container">
    <div class="row pt-5 align-items-center flex-column text-center">
      <h2 class="footer-title col-12 col-md-7 my-4">
        {{ "footer:landing" | translate }}
      </h2>
      <p class="footer-landing col-12 col-md-9">
        {{ "description:footer:landing" | translate }}
      </p>
    </div>
  </div>
</div>
