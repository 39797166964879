import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  public loading: boolean = true;
  public currentLang: string = 'en';
  public infoCards: any = [
    {
      level: 'Level7:Perplexity',
      img: '/assets/img/dh-landing/level7.png',
      subtitle: 'level:7:short:description',
      description: '',
      button: '',
      color: '#5B1F78',
    },
    {
      level: 'Level6:Simplexity',
      img: '/assets/img/dh-landing/level6.png',
      subtitle: 'level:6:short:description',
      description: '',
      button: '',
      color: '#004B93',
    },
    {
      level: 'Level5:Complexity',
      img: '/assets/img/dh-landing/level5.png',
      subtitle: 'level:5:short:description',
      description: '',
      button: '',
      color: '#009EE0',
    },
    {
      level: 'Level4:Relationality',
      img: '/assets/img/dh-landing/level4.png',
      subtitle: 'level:4:short:description',
      description:
        'Do you want to know more about your behaviour in a Team? Or about how well the roles in your team are adjusted to what you are doing at the moment? Then create a Teamrole profile.',
      button: 'Learn more',
      color: '#41A62A',
      route: 'level-4',
    },
    {
      level: 'Level3:Maximality',
      img: '/assets/img/dh-landing/level3.png',
      subtitle: 'level:3:short:description',
      description:
        'We are currently creating the questionnaire to create your Double Healix Competency profile. You can try it out for free.',
      button: 'Learn more',
      color: '#FFCC00',
      route: 'level-3',
    },
    {
      level: 'Level2:Sensationality',
      img: '/assets/img/dh-landing/level2.png',
      subtitle: 'level:2:short:description',
      description: '',
      button: '',
      color: '#EE7F00',
    },
    {
      level: 'Level1:Simplicity',
      img: '/assets/img/dh-landing/level1.png',
      subtitle: 'level:1:short:description',
      description: '',
      button: '',
      color: '#E2001A',
    },
    {
      level: 'Level0:Universality',
      img: '/assets/img/dh-landing/level0.png',
      subtitle: 'level:0:short:description',
      description: '',
      button: '',
      color: '#8A9199',
    },
  ];
  public currentRoute: string = '';
  constructor(
    private lang: LangService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.lang.language.subscribe((lang) => {
      this.currentLang = lang || 'en';
    });
  }
  ngOnInit(): void {
    this.activatedRoute.url.subscribe((url) => {
      this.currentRoute = url.join('/');
      setTimeout(() => {
        if (this.currentRoute) {
          if (this.currentRoute == 'doublehealixprofiel') {
            this.lang.setLanguage('nl');
          }
          if (this.currentRoute == 'doublehealixprofile') {
            this.lang.setLanguage('en');
          }
        }
      }, 1000);
    });
    this.activatedRoute.params.subscribe(({ lang }) => {
      setTimeout(() => {
        if (lang) {
          if (lang == 'nl') {
            this.lang.setLanguage('nl');
          }
          if (lang == 'en') {
            this.lang.setLanguage('en');
          }
        }
      }, 1000);
    });
  }
  ngAfterViewInit(): void {
    this.checkCurrentRoute();
  }

  public checkCurrentRoute() {
    // Verifica si la URL actual es /dh/level-3 o /dh/level-4
    if (this.router.url === '/dh/landing/nl') {
      this.router.navigate(['/doublehealixprofiel']);
    }
    if (this.router.url === '/dh/landing/en') {
      this.router.navigate(['/doublehealixprofile']);
    }
  }
  goTo(route: any) {
    this.router.navigate(['/dh', route]);
  }

  goToLevel3() {
    if (this.currentLang == 'en') {
      this.router.navigate(['doublehealixprofile', 'competencies']);
    } else {
      this.router.navigate(['doublehealixprofiel', 'competenties']);
    }
  }
  goToLevel4() {
    if (this.currentLang == 'en') {
      this.router.navigate(['doublehealixprofile', 'teamroles']);
    } else {
      this.router.navigate(['doublehealixprofiel', 'teamrollen']);
    }
  }
}
