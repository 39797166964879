<div class="modal-close" (click)="closeModal()">
  <fa-icon class="icon me-1" [icon]="faTimes"></fa-icon
  ><span>{{ "btn:close" | translate }}</span>
</div>
<div class="modal-header">
  <h4 class="modal-title">
    {{ "courses:modal:reviews:add:title" | translate }}
  </h4>
</div>
<div class="modal-body p-4">
  <div class="form-group" *ngIf="isAdmin()">
    <ml-switch
      [inputValue]="changeUser"
      textAlignClass="ml-switch-text-right"
      textContent="{{ 'Write review as another user' | translate }}"
      (value)="getChangeInput($event)"
      class="mb-4"
    ></ml-switch>
    <div class="mt-3" *ngIf="changeUser">
      <app-search-users
        [internalUsers]="false"
        id="search"
        (searchEvent)="usersSelected($event)"
        type="text"
        [disable]="selectUsers.length == 1"
        placeholder="{{ 'play_video:video_gift:enter_name' | translate }}"
      ></app-search-users>
    </div>
    <div class="row">
      <div class="col-10 px-1">
        <app-users-invited
          [fromReview]="true"
          [usersSelected]="selectUsers"
        ></app-users-invited>
      </div>
    </div>
  </div>
  <div class="form-group">
    <label class="modal-label" for="rating">{{ "Rating" | translate }}</label>
    <p class="text-explanation">
      {{ "Click on the stars to give a rating" | translate }}
    </p>
    <div class="col-12 mb-1">
      <ngb-rating id="rating" [(rate)]="review">
        <ng-template let-fill="fill" let-index="index">
          <span class="star" [class.filled]="fill === 100"
            ><i class="fas fa-star"></i
          ></span>
        </ng-template>
      </ngb-rating>
    </div>
    <label class="modal-label" for="clip-title">{{
      "courses:modal:reviews:add:review_title" | translate
    }}</label>
    <input
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{
        'courses:modal:reviews:add:title:placeholder' | translate
      }}"
      required=""
      type="text"
      [(ngModel)]="title"
    />
    <label class="modal-label" for="clip-title">{{ "Name" | translate }}</label>
    <input
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{ 'review:name:placeholder' | translate }}"
      required=""
      type="text"
      [(ngModel)]="username"
    />
    <label class="modal-label" for="clip-title">{{
      "Job title" | translate
    }}</label>
    <input
      class="input-box w-100"
      id="clip-title"
      maxlength="80"
      name="name"
      placeholder="{{
        'courses:modal:reviews:add:jobtitle:placeholder' | translate
      }}"
      required=""
      type="text"
      [(ngModel)]="job_title"
    />
    <label class="modal-label" for="clip-title">{{
      "courses:modal:reviews:add:description" | translate
    }}</label>
    <textarea
      class="input-box w-100"
      name=""
      id=""
      cols="30"
      rows="5"
      placeholder="{{
        'courses:modal:reviews:add:description:placeholder' | translate
      }}"
      [(ngModel)]="description"
    ></textarea>
  </div>

  <hr class="common-separator" />
  <div class="form-group">
    <div class="row justify-content-end mx-1">
      <button
        [disabled]="
          title == '' ||
          username == '' ||
          username == '' ||
          job_title == '' ||
          description == '' ||
          review <= 0 ||
          (changeUser && selectUsers.length == 0)
        "
        (click)="createReview()"
        type="button"
        role="tab"
        class="btn btn-primary btn-create modal-btn-width float-right ms-2"
      >
        {{ "Save" | translate }}
        <fa-icon [icon]="faCircleNorch" *ngIf="loading" [spin]="true"></fa-icon>
      </button>
    </div>
  </div>
</div>
