<div class="header-container">
  <header class="py-1">
    <div
      [ngClass]="{ 'justify-content-between': isAuthenticated() }"
      class="container-fluid d-flex flex-wrap align-items-center justify-content-md-between py-2 header"
    >
      <div
        class="col-3 col-lg-auto d-flex"
        [ngClass]="{ 'col-lg-auto': !isAuthenticated() }"
      >
        <button
          (click)="toggleSidebar()"
          class="btn btn-outline-primary d-flex d-lg-none me-1"
          type="button"
          aria-label="Toggle navigation"
        >
          <fa-icon [icon]="faBars"></fa-icon>
        </button>
        <a
          href="/"
          class="d-inline-flex align-items-center text-dark text-decoration-none"
        >
          <div class="logo">
            <app-organization-logo
              [organization]="currentOrganization"
            ></app-organization-logo>
          </div>
          <span class="h6 ms-2 mb-0 text-primary d-none d-lg-inline font-bold"
            >MovieLearning</span
          >
        </a>
        <div *ngIf="isAuthenticated()" class="col-auto d-none d-lg-inline">
          <div ngbDropdown #myDrop="ngbDropdown" class="d-inline-block mx-3">
            <button
              type="button"
              class="btn dropdown-org d-flex align-items-center justify-content-start"
              id="dropdownBasic1"
              ngbDropdownToggle
              data-toggle="tooltip"
              data-placement="bottom"
              [title]="currentOrganization?.name"
            >
              <p class="title-organization my-auto ms-2">
                {{( currentOrganization?.name || "Organization") | excerpt : 19 }}
              </p>
            </button>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdownBasic1"
              class="dropdown-menu"
            >
              <div
                (click)="$event.stopPropagation(); myDrop.close()"
                class="second-container"
              >
                <!-- <a class="d-flex ps-2 org-line" [routerLink]="['/admin']">
                  <app-organization-logo
                    [organization]="{ logo_id: '', name: 'M' }"
                  ></app-organization-logo>
                  <div
                    class="flex-grow-1 d-flex align-items-center mx-2 text-dark fw-600 fs-14"
                  >
                    {{ "MovieLearning Admin" }}
                  </div>
                </a> -->

                <div *ngFor="let organization of organizations">
                  <a
                    class="d-flex ps-2 org-line"
                    (click)="switchOrganization(organization)"
                    *ngIf="
                      organization.Organization_Member?.status == 'accepted' ||
                      isAdmin()
                    "
                  >
                    <app-organization-logo
                      [organization]="organization"
                    ></app-organization-logo>
                    <div
                      class="flex-grow-1 d-flex align-items-center mx-2 text-dark fw-600 fs-14"
                    >
                      {{ organization?.name }}
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-none d-lg-flex col-lg-auto"
        [ngClass]="{ 'col-lg-auto justify-content-center': !isAuthenticated() }"
      >
        <app-nav *ngIf="showItems"></app-nav>
      </div>

      <div
        class="col-auto col-lg-auto text-end pe-lg-3"
        [ngClass]="{ 'col-lg-auto': !isAuthenticated() }"
      >
        <app-user-menu [showItems]="showItems"></app-user-menu>
      </div>
    </div>
  </header>
</div>
