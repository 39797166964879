import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CollectionService } from 'src/app/services/collection.service';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { ResultComponent } from 'src/app/modals/checkout-questionnaire/result/result.component';
import { MainComponent } from 'src/app/modals/checkout-questionnaire/main/main.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DhprofileService } from 'src/app/services/dhprofile.service';
import { AuthService } from 'src/app/services/auth.service';
import { Questionnaire } from 'src/app/interfaces/questionnaire';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-level4',
  templateUrl: './level4.component.html',
  styleUrls: ['./level4.component.scss'],
})
export class Level4Component implements OnInit, AfterViewInit {
  faCircleNorch: any = faCircleNotch;
  public collection: any = [];
  public loading: boolean = true;
  questionnaires: Array<Questionnaire> = [];
  public currentLang: string = 'en';
  isInSpecificRoute: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cs: CollectionService,
    private ngbModal: NgbModal,
    private dhprofileService: DhprofileService,
    private auth: AuthService,
    private lang: LangService
  ) {
    this.route.url.subscribe(() => {
      if (this.router.url === '/doublehealixprofile/teamroles') {
        this.lang.setLanguage('en');
        this.currentLang = 'en';
      }
      if (this.router.url === '/doublehealixprofiel/teamrollen') {
        this.lang.setLanguage('nl');
        this.currentLang = 'nl';
      }
    });
  }
  ngAfterViewInit(): void {
    this.checkCurrentRoute();
  }

  ngOnInit(): void {
    this.loading = true;
    this.cs
      .getCollection(10, 1, 'quest_level_4', 1, '', 'true', null, '', null)
      .then((res) => {
        if (res.data && res.data.length > 0) {
          this.collection = res.data; //me quedo con el primero, ya q solo hay una collection tipo questionario nivel 4 por empresa.
          this.loading = false;
        }
        console.log('this.collection', this.collection);
      });
    let id_user = null;
    this.getQuestionnaires(id_user);
  }

  checkCurrentRoute() {
    // Verifica si la URL actual es /dh/level-3 o /dh/level-4
    if (this.router.url === '/dh/level-4') {
      let language: any = '';
      language = localStorage.getItem('language') || this.currentLang;
      // Redirige a la ruta deseada
      if (language == 'nl') {
        this.lang.setLanguage('nl');
        this.router.navigate(['/doublehealixprofiel/teamrollen']);
      } else {
        this.router.navigate(['/doublehealixprofile/teamroles']);
        this.lang.setLanguage('en');
      }
    }
  }
  public isNormalUser() {
    return this.auth.hasAnyRole(['user', 'normal']);
  }

  acceptAndOpenQuestionnaire(id: number) {
    this.router.navigate(['/dh', 'questionnaire', id]);
  }

  getQuestionnaires(id: any) {
    if (this.auth.isAuthenticated()) {
      this.dhprofileService.getQuestionnairesList(id).then((list) => {
        if (this.isNormalUser()) {
          //     console.log('normal user')
          this.questionnaires = list.filter(
            (item: any) =>
              (item?.dh_team?.hypothetical == false || !item?.dh_team) &&
              item.level == 4
          );
        } else {
          //    console.log('Not normal user')

          this.questionnaires = list;
        }

        if (this.questionnaires.length) {
          this.route.queryParams.subscribe((params) => {
            if (params.order && params.status) {
              console.log('hay una orden');
              let modalRef = this.ngbModal.open(ResultComponent, {
                windowClass: 'default-modal',
                size: '600',
                centered: true,
              });
              modalRef.componentInstance.selfModal = modalRef;
              modalRef.componentInstance.orderId = params.order;
              modalRef.componentInstance.success = params.success;
              modalRef.componentInstance.status = params.status;
              modalRef.componentInstance.questionnaireId =
                this.questionnaires[this.questionnaires.length - 1].id;
              modalRef.componentInstance.ngOnChanges();
            }
          });
        }
        // this.dhService.getUser().then((resp) => {
        //   this.userId = resp.data.user.id;
        //   this.retake = this.userId == this.questionnaires[0]?.user_id;
        // });
      });
    }
  }

  getFormLevel4(modalInfo = null) {
    console.log('get_form', this.collection);
    let modalRef = this.ngbModal.open(MainComponent, {
      windowClass: 'default-modal',
      size: '600',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.collections = this.collection;
    modalRef.componentInstance.modalInfo = modalInfo;
    modalRef.componentInstance.selfModal = modalRef;
    modalRef.componentInstance.initialDays = 30;
    modalRef.componentInstance.ngOnChanges();
    modalRef.componentInstance.openModal.subscribe((data: any) => {
      this.getFormLevel4();
    });
  }
  goBack() {
    if (this.currentLang == 'nl') {
      this.router.navigate(['/doublehealixprofiel']);
    } else {
      this.router.navigate(['/doublehealixprofile']);
    }
    // this.router.navigate(['/dh/landing']);
  }
}
