import { CoursesService } from './../../../services/courses.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  faCircleNotch,
  faStar,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModalRef, NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-create-review-modal',
  templateUrl: './create-review-modal.component.html',
  styleUrls: ['./create-review-modal.component.scss'],
})
export class CreateReviewModalComponent implements OnInit {
  @Output() getReviews: EventEmitter<any> = new EventEmitter();

  @Input() selfModal: NgbModalRef | null = null;
  @Input() course: any;
  @Input() reviewId: any = 0;
  @Input() reviewToEdit: any;
  faStar = faStar;

  review: number = 0;
  title: string = '';
  username: string = '';
  job_title: string = '';
  description: string = '';
  userId: number = 0;
  faTimes = faTimes;
  faCircleNorch = faCircleNotch;
  loading = false;
  public changeUser: boolean = false;
  public selectUsers: any = [];
  public idAnotherUSer: string = '';
  constructor(
    config: NgbRatingConfig,
    private toastService: ToastService,
    private translate: TranslateService,
    private courseService: CoursesService,
    public authService: AuthService
  ) {
    config.max = 5;
  }

  ngOnInit(): void {
    if (this.reviewId) {
      this.fillForm();
    }

    this.courseService.getUser().then((data) => {
      this.userId = data.data.user.id;
    });
  }

  closeModal() {
    if (this.selfModal) this.selfModal?.close();
    else $('ngb-modal-window').click();
  }

  public createReview() {
    this.loading = true;

    if (this.reviewId) {
      let dataToedit: any = {
        review: this.review,
        title: this.title,
        username: this.username,
        job_title: this.job_title,
        description: this.description,
        userId: this.userId,
      };
      let dataReview = { ...this.reviewToEdit, ...dataToedit };

      this.courseService
        .editReview(this.course.id, this.reviewId, dataReview)
        .then((resp) => {
          console.log(resp);
          this.loading = false;
          this.getReviews.emit();
          if (resp.result == 'success') {
            this.toastService.showSuccess(
              this.translate.instant('Review edited')
            );
          }
          this.closeModal();
        })
        .catch((err: any) => {
          console.log(err);
          this.loading = false;
          this.closeModal();
          this.toastService.showError(
            this.translate.instant('Error editing review')
          );
        });
    } else {
      let dataReview: any = {
        review: this.review,
        title: this.title,
        username: this.username,
        jobTitle: this.job_title,
        description: this.description,
        userId: this.changeUser ? this.idAnotherUSer : this.userId,
      };
      this.courseService
        .createReview(dataReview, this.course.id)
        .then((res) => {
          this.toastService.showSuccess(
            this.translate.instant('Review created')
          );
          this.getReviews.emit();
        })
        .catch((err: any) => {
          let message = this.translate.instant('Error creating review');
          if (err.error?.message?.friendly) {
            message = err.error?.message?.friendly;
          }
          this.toastService.showError(message);
          this.getReviews.emit();
        })
        .finally(() => {
          this.loading = false;
          this.closeModal();
        });
    }
  }
  public fillForm() {
    this.review = this.reviewToEdit.review;
    this.title = this.reviewToEdit.title;
    this.username = this.reviewToEdit.username;
    this.job_title = this.reviewToEdit.job_title;
    this.description = this.reviewToEdit.description;
  }
  public getChangeInput($event: any) {
    this.changeUser = $event;
    if (!this.changeUser) {
      this.selectUsers = [];
      this.idAnotherUSer = '';
    }
  }
  public usersSelected(data: any) {
    this.selectUsers = data;
    if (this.changeUser) {
      this.idAnotherUSer = this.selectUsers[0].id;
    }
  }

  isAdmin(): boolean {
    return this.authService.hasAnyRole(['super_user']);
  }
}
