<div class="select-users">
  <div class="box-input">
    <div *ngIf="internalUsers">
      <div
        *ngFor="let user of usersSelected; index as index"
        class="user-selected"
        ng-style="{ width: maxUsers == usersSelected.length && '97%' }"
      >
        <img
          src="{{
            user.avatarPathUrlSmall ||
              user.avatar ||
              'assets/img/avatars/profile-avatar-purple.svg'
          }}"
        />
        {{ user.display_name }}
        <span (click)="onRemove(index)">X</span>
      </div>
    </div>

    <input
      id="selectMembersInpt"
      class="input-box"
      type="text"
      [disabled]="disable"
      (keyup)="onSearch()"
      [(ngModel)]="searchTxt"
      placeholder="{{ placeholder }}"
      *ngIf="maxUsers != null && maxUsers > usersSelected.length"
    />
  </div>
  <ng-container *ngIf="searchTxt != '' && !loadingUsers">
    <div
      class="list-search"
      *ngIf="
        (usersSearch && usersSearch.length && !loadingUsers) ||
        ifIsValidToInvite
      "
    >
      <div
        *ngFor="let user of usersSearch"
        (click)="onAdd(user)"
        style="display: flex"
        [ngClass]="{
          disable:
            (user?.dh_profiles?.length == 0 && fromDHSubTeam) || user?.excluded
        }"
      >
        <img
          src="{{
            user.avatarPathUrlSmall ||
              user.avatar ||
              'assets/img/avatars/profile-avatar-purple.svg'
          }}"
        />
        <span style="float: left">{{ user.display_name }}</span>
        <span
          *ngIf="
            user?.dh_profiles?.length == 0 && fromDHSubTeam && !user?.excluded
          "
          style="float: right"
          class="text-danger col text-end"
          >{{ "user without dh profile" | translate }}
        </span>
        <span
          *ngIf="user?.excluded"
          style="float: right"
          class="text-danger col text-end"
          >{{ usersExcludedMsg | translate }}
        </span>
      </div>
      <div
        (click)="onInvite(searchTxt)"
        *ngIf="
          usersSearch &&
          usersSearch.length == 0 &&
          !loadingUsers &&
          ifIsValidToInvite
        "
      >
        <img src="assets/img/avatars/profile-avatar-gray.svg" />
        {{
          isAdmin() && fromDHSubTeam
            ? "This email address does not match an account."
            : "This email address do not match an account."
        }}
      </div>
    </div>
  </ng-container>

  <div class="list-search">
    <div
      *ngIf="searchTxt && usersSearch && usersSearch.length == 0 && delaySearch"
    >
      <img src="assets/img/avatars/profile-avatar-gray.svg" />
      {{ "This email address does not match an account." }}
    </div>
  </div>
  <div class="list-search" *ngIf="loadingUsers && searchTxt != ''">
    <div>Loading..</div>
  </div>
</div>
