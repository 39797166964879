<div class="modal-close"  (click)="closeModal()">
  <i class="fas fa-times me-1"></i><span >{{ "btn:close" | translate }}</span>
</div>

<div class="modal-header">
  <h4 class="modal-title">{{ "course:buy:title" | translate }}</h4>
</div>

<div class="modal-body">
  <div class="text-center">
    <app-movie-tabs-wrapper [main]="true" [update]="tabs.length == 4">
      <li class="nav-item" role="presentation" *ngFor="let tab of tabs; let i = index">
        <button class="nav-link py-3" data-bs-toggle="tab" [attr.data-bs-target]="'#' + tab.id"
          type="button" role="tab" (click)="changeTab(i)" [ngClass]="{'disabled': !tabs[i].enabled, 'active': i == 0}">
          <span *ngIf="tab.id != 'login'">{{ 'checkout:modal:tab:' + tab.id | translate }}</span>
          <span *ngIf="tab.id == 'login'">{{ 'login:menu:title' | translate }}</span>
        </button>
      </li>
    </app-movie-tabs-wrapper>
  </div>

  <div class="tab-content mt-2">
    <div class="tab-pane fade show p-5" id="login" role="tabpanel" [ngClass]="{'active': tabs[0].id == 'login'}">
      <app-login (success)="logedIn()" [state]="'register'" [messageRegister]="true" [openNormalLogin]="true"></app-login>
    </div>

    <div class="tab-pane fade show pt-2" *ngIf="auth.isAuthenticated()" id="plan" role="tabpanel" [ngClass]="{'active': tabs[0].id == 'plan'}">
      <app-plan-tab [isGift]="isGift" [subscriptions]="subscriptions" [initial]="initialDays" (changed)="onSubscriptionChange($event)"></app-plan-tab>
    </div>

    <div class="tab-pane fade show pt-2" *ngIf="auth.isAuthenticated()" id="gift" role="tabpanel" [ngClass]="{'active': tabs[0].id == 'gift'}">
      <app-gift-tab></app-gift-tab>
    </div>

    <div class="tab-pane fade pt-2" *ngIf="auth.isAuthenticated()" id="method" role="tabpanel">
      <app-method-tab *ngIf="this.checkout.order"></app-method-tab>
    </div>

    <div class="tab-pane fade pt-2" *ngIf="checkout.subscription" id="confirm" role="tabpanel">
      <app-order-preview-tab
      [finish_subscription]="finish_subscription"></app-order-preview-tab>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="container">
    <div class="row justify-content-end">
      <div class="col-6 px-0" *ngIf="currentTab > 0">
        <button class="btn btn-link" (click)="prevTab()"><i class="icon fa fa-angle-left"></i> {{ 'video:edit:modal:prev_step' | translate }}</button>
      </div>
      <div class="col-6 text-end px-0">
        <button class="btn btn-primary" (click)="nextTab()" *ngIf="currentTab < tabs.length - 1" [disabled]="!canGoNext()">{{ 'video:edit:modal:next_step' | translate }} <i class="icon fa fa-angle-right"></i></button>
        <button class="btn btn-primary" *ngIf="currentTab == tabs.length - 1" [disabled]="!this.checkout.termsAccepted" (click)="pay()">
          {{ "course:buy:payment:btn:pay" | translate }} {{getTotalValue() | currency:'EUR':'symbol':'1.2-2' }}
          <!-- {{ "course:buy:payment:btn:pay" | translate }} {{ this.checkout.subscription!.subscription_days == this.checkout.course!.summaryOrders.subscription_day_selected ? (this.checkout.subscription!.price > 0 ? (this.checkout.subscription!.price | currency:'EUR':'symbol':'1.0-2') : (this.checkout.subscription!.price | currency:'EUR':'symbol':'1.0-2')) : this.checkout.subscription!.originalPrice ? (this.checkout.subscription!.originalPrice | currency:'EUR':'symbol':'1.0-2') : (this.checkout.subscription!.price > 0 ? (this.checkout.subscription!.price | currency:'EUR':'symbol':'1.0-2') : (this.checkout.subscription!.price | currency:'EUR':'symbol':'1.0-2')) }} -->
          <span *ngIf="this.checkout.subscription!.price > 0">{{ "course:buy:payment:btn:via" | translate }} {{ "course:buy:payment:method:" + this.checkout.order!.method | translate }}</span>
          <i *ngIf="paying" class="fa fa-circle-notch fa-spin ms-2"></i></button>
      </div>
    </div>
  </div>
</div>
