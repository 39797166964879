<label class="ml-switch {{ textAlignClass }}">
  <span
    class="ml-switch-text fw-bold"
    [ngStyle]="{ 'font-size': small ? '12px' : '14px' }"
    >{{ textContent | translate}}</span
  >
  <input
    class="ml-switch-input"
    [(ngModel)]="inputValue"
    type="checkbox"
    (click)="onChanged()"
  />
  <div
    class="ml-switch-graph"
    [ngStyle]="{
      height: small ? '16px' : '20px',
      width: small ? '30px' : '35px',
      top: small ? '5px' : '2px'
    }"
  >
    <div
      class="ml-switch-circle"
      [ngStyle]="{
        height: small ? '16px' : '20px',
        width: small ? '16px' : '20px'
      }"
    ></div>
  </div>
</label>
