import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-users-invited',
  templateUrl: './users-invited.component.html',
  styleUrls: ['./users-invited.component.scss'],
})
export class UsersInvitedComponent implements OnInit, OnChanges {
  @Input() usersSelected: any[] = [];
  @Input() fromDHSubTeam: boolean = false;
  @Input() fromReview: boolean = false;
  @Output() addUserFromSubTeam = new EventEmitter<any>();
  public textEdit: boolean = false;
  public showInput: boolean = false;
  constructor() {}
  
  ngOnInit(): void {
    for (let i = 0; i < this.usersSelected.length; i++) {
      this.usersSelected[i]['select'] = 'Select dhProfile';
    }    
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.focusInput();
  }


  deleteUserInvited(index: number) {
    this.usersSelected.splice(index, 1);
  }

  selectUserInvited(user: any, index: number) {
    this.usersSelected[index].select =  user?.dh_team?.name || user.id 
    this.usersSelected[index].id = user.id;
    this.addUserFromSubTeam.emit({ index, user });
  }
  focusInput() {
    setTimeout(() => {
    const inputElement = document.getElementById(
      'selectMembersInput'
    ) as HTMLInputElement;
    if (inputElement){      inputElement.focus();}
    }, 1000);
  }
}
