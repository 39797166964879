export const environment = {
  production: true,
//  apiUrl: "https://api.move-e-learning.com", 
  apiUrl: "https://api.movie-dev.staging3.keetup.com",
  apiUrl_v1: "https://api2.movie-dev.staging3.keetup.com/api/v1",
  baseDomain: "movie-develop.staging.keetup.com",
  public: "http://courses.movie-develop.staging3.keetup.com",
  auth: {
    grantType: "password",
    clientId: "movielearning",
    clientSecret: "9SVSwVCJcpRb9Dq6N8acKsBt92MX4uPg",
  },
  googleAnalyticsId: "UA-93083097-1s",
  compentenciCourseId: "427",
  nl_compentencyCourseId: '427',
  en_compentencyCourseId: '427',
  nl_compentencyPrice: '24.5',
  en_compentencyPrice: '24.5',
  vixyPId: 257,
  vixyPlayerId: 23449285,
  kpnOrganizationId: 5,
//  staticUploadUrl: 'https://static.move-e-learning.com/',
  staticUploadUrl: 'https://static.movie-dev.staging3.keetup.com/',
  videoMaxSize: 300,
  videoMaxSizeAdmin: 10240,
  URL_PLATFORM: 'https://movie-develop.staging3.keetup.com/',
  clarityCode: 'o9879aiq17'



};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
